import React from 'react'
import {
  Header,
  Heading,
  FaqZone, 
  Footer
} from '../components'

import { AppText } from '../model/AppDatas'

interface HomeProps {
  title?: string
}

function Faq(props: HomeProps) {
  const {
    icon,
    title,
    subtitle,
    link,
    item
  } = AppText.fr.faq;

  const { footer } = AppText.fr;

  return (
    <div className=''>
      <Header />
      <Heading {...{ icon, title, subtitle, link, }} />
      <FaqZone {...{ item}} />
      <Footer {...{ footer } }/>
    </div>
  )
}

export default Faq
