import React, { useContext } from 'react'
import './Heading.sass'
import { Outlet, Link } from "react-router-dom";

import { ThemeContext } from '../../App'
import { Message } from '../../assets'
const genIcon = (icon: string) => {
    let Icon = undefined;
    switch (icon) {
      case "Message":
        Icon = <Message className='heading__item-icon' />
        break; 
      default:
        Icon = <Message className='heading__item-icon' />
        break;
    }
    return Icon
  }

interface HeadingProps {
  icon?: string
  title?: string
  subtitle?: string
  link?: {
    text?: string,
    url?: string,
  }
}

function Heading(props: HeadingProps) {
  const {
    icon,
    title,
    subtitle,
    link,
  } = props;
  return (
    <div className='heading'>
      {icon &&
        <p className="heading__icon">
          {genIcon(icon)}
        </p>
      }
      {title &&
        <p className="heading__title">
          {title}
        </p>
      }
      {subtitle &&
        <p className="heading__subtitle">
          {`${subtitle} : `}
          
          {link &&
        <Link to={`${link.url}`}>
          <a href={link.url} className="heading__text">
            {link.text}
          </a>
        </Link>
      }
        </p>
      }
      
    </div>
  )
}

export default Heading
