import React, { useState } from "react";
import "./SubscribeZone";
import { AppText } from "../../model/AppDatas";
import { useForm, SubmitHandler } from "react-hook-form";

interface SubscribeZoneProps {
  test?: string;
}
type Inputs = {
  email?: string;
};

interface IResponse {
  msg?: string;
  status?: number | null;
}

function SubscribeZone(props: SubscribeZoneProps) {
  const [response, setResponse] = useState<IResponse>({
    msg: "",
    status: null,
  });
  const { title, subtitle, litletext, placeholder, btnText, footerText } =
    AppText.fr.subscribe;

  const {
    register,
    handleSubmit,
    reset,
    formState,
    formState: { isSubmitSuccessful },
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    setResponse({
      msg: "Veuillez patienter ...",
      status: 200,
    });
    fetch("https://dico-mailer.yaqar.org/subscribe/", {
      method: "POST", // or 'PUT'
      mode: "no-cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // inc
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response)
      .then((data) => {
        setResponse({
          msg: "Désormais vous serez informé des évolutions du projet. Soyez bénis.",
          status: 200,
        });
      })
      .then(() => {
        setTimeout(() => {
          setResponse({
            msg: "",
            status: null,
          });
        }, 10000);
      })
      .then(() => {
        setTimeout(() => {
          setResponse({
            msg: "",
            status: null,
          });
        }, 10000);
      })
      .catch((error) => {
        setResponse({
          msg: "",
          status: null,
        });
      });
  };

  React.useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({ email: "" });
    }
  }, [formState, reset]);
  return (
    <div className="subscribe">
      <div className="subscribe__zone">
        {title && <p className="subscribe__title">{title}</p>}
        {subtitle && <p className="subscribe__subtitle">{subtitle}</p>}
        {litletext && <p className="subscribe__litletext">{litletext}</p>}
        {placeholder && (
          <form className="subscribe__form" onSubmit={handleSubmit(onSubmit)}>
            <input
              className={`subscribe__input ${errors.email && "__error"}`}
              placeholder={placeholder}
              aria-label={placeholder}
              id="email"
              type="email"
              {...register("email", {
                required: "Vous devez renseigner une adresse mail valide",
                pattern: /\S+@\S+\.\S+/,
              })}
            />
            <button className="subscribe__btn" type="submit">
              {btnText}
            </button>
          </form>
        )}
        {errors.email && (
          <span className="__error">{errors.email?.message}</span>
        )}
        <div className="contact__input-bloc">
          {response.status === 200 && (
            <p className="contact__input-group text-green-600 text-xl">
              {response.msg}
            </p>
          )}
        </div>
        {footerText && <p className="subscribe__footerText">{footerText}</p>}
      </div>
    </div>
  );
}

export default SubscribeZone;
