export function useDemo() {
    const __active = () =>
        document
            .querySelectorAll(".demo__bible-item-li")[0]
            ?.classList?.add("__active");

    const __scribe = (verset: string) => {
        const transcript = verset?.replace(". ", "/")
            .replace(":", "/")
            .toLowerCase();
        return transcript;
    };


    return {
        __active,
        __scribe,
    }
}
