import React, { useContext } from 'react'

export interface FaqItemProps {
    question?: string,
    answer?: string,
}

function FaqItem(props: FaqItemProps) {
    const {
        question,
        answer
    } = props;
    return (
        <div className='faq__item'>
            {question && <p className='faq__question'>
                {question}
            </p>
            }
            {answer &&
                <p className='faq__answer'>
                    {answer}
                </p>
            }
        </div>
    )
}

export default FaqItem
