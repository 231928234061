import React from "react";
import { Outlet, Link } from "react-router-dom";

import { Header, Footer } from "../components";

import {
  FeatureZoneDatas,
  FeatureDetailsDatas,
  AppText,
} from "../model/AppDatas";

interface HomeProps {
  title?: string;
}

function NoPage(props: HomeProps) {
  const { footer } = AppText.fr;

  return (
    <div className="screen">
      <Header />
      <div className="nopage">
        <div className="nopage__404">404</div>
        <Link to="/">
          <p className="nopage__text">Retournons à l'accueil.</p>
        </Link>
      </div>
      <Footer {...{ footer }} />
    </div>
  );
}

export default NoPage;
