import React, { useContext } from 'react'
import './FaqZone.sass'
import { Outlet, Link } from "react-router-dom";

import { ThemeContext } from '../../App'
import FaqItem, { FaqItemProps } from './FaqItem/FaqItem'

interface FaqZoneProps {
  item: FaqItemProps[]
}

function FaqZone(props: FaqZoneProps) {
  const {
    item
  } = props;
  return (
    <div className='faq'>

      {item &&
        item.map(({ question, answer }, index) =>
          <FaqItem {...{ question, answer }} />
        )
      }


    </div>
  )
}

export default FaqZone
