import React from 'react'
import {
  Header,
  Heading,
  ContactForm,
  Footer
} from '../components'

import { AppText } from '../model/AppDatas'

interface HomeProps {
  title?: string
}

function Contact(props: HomeProps) {
  const {
    icon,
    title,
    subtitle
  } = AppText.fr.contact;
  const { footer } = AppText.fr;
  const { contact } = AppText.fr;

  return (
    <div className=''>
      <Header />
      <Heading {...{ icon, title, subtitle }} />
      <ContactForm {...{contact}}/>
      <Footer {...{ footer } }/>
    </div>
  )
}

export default Contact
