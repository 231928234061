import React, { Component } from "react";
import { FeatureZoneItemPropsTypes } from "../../../types/AppTypes";
import { DbBook, DbText, DbConsole } from "../../../assets";
function FeatureZoneItem({
  icon,
  title,
  description,
}: FeatureZoneItemPropsTypes) {
  const genIcon = (icon: string) => {
    let Icon = undefined;
    switch (icon) {
      case "DbBook":
        Icon = <DbBook className="feature__item-icon" />;
        break;
      case "DbConsole":
        Icon = <DbConsole className="feature__item-icon" />;
        break;
      case "DbText":
        Icon = <DbText className="feature__item-icon" />;
        break;
      default:
        break;
    }
    return Icon;
  };
  return (
    <div className="feature__item">
      {icon && genIcon(icon)}
      {title && <h5 className="text-gradient">{title}</h5>}
      {description && <p>{description}</p>}
    </div>
  );
}

export default FeatureZoneItem;
