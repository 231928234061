import React, { ReactElement, ReactNode } from "react";
import "./FeatureZone.sass";
import { FeatureZonePropsTypes } from "../../types/AppTypes";
import FeatureZoneItem from "./FeatureZoneItem/FeatureZoneItem";
import FeatureDetail from "./FeatureZoneItem/FeatureDetail";

function FeatureZone(props: FeatureZonePropsTypes) {
  const { feature, details } = props;
  return (
    <div>
      <div className="feature" id="feature">
        {feature.map(({ icon, title, description }, index) => (
          <div className="feature__bloc">
            <FeatureZoneItem key={index} {...{ icon, title, description }} />
          </div>
        ))}
      </div>
      <div className="detail">
        {details.map(({ tag, title, subtitle, details, img }, index) => (
          <FeatureDetail
            key={index}
            {...{ tag, title, subtitle, details, img }}
          />
        ))}
      </div>
    </div>
  );
}

export default FeatureZone;
