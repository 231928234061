import React, { ComponentElement } from 'react';



export const DemoDatas = [
  {
    id: `00000`,
    word: `‘Aharown`,
    part: `Le Réforme`,
    preview: `Aaron`,
    origin: `’AHAROWN, (n. m.) d’origine ‘ivriyt : אַהֲרוֹן (a-ha-rône’) : « haut placé » ou « éclairé ».`,
    text: `Premier fils de ‘Amram et de Yowkèvèd, descendant de Léwiy. Petit-frère de Myriam et frère aîné de Moshèh (Ex. 7:7) dont il fut le porte-parole devant Par’oh[1] et souvent devant le peuple de Yisra’él. ’Aharown fut mis à part par יהוה pour être sanctifié. 
    Il fut le premier grand-prêtre de Yisra’él assigné au sacerdoce. Ainsi tous les prêtres en service dans le Tente de la Rencontre ou dans le Temple de Shlomoh devaient être descendants de la maison (ou de la famille) d’Aharown selon l’ordre de YHWH (Ex. 30:30 ; 40:13-15 ; No. 18:1, 7). 
    Il fit un veau d’or par lequel Yisra’él pécha devant יהוה pendant que Moshèh recevait les instructions d’Èlohiym sur la montagne. Plus tard, à la quarantième année après que les enfants de Yisra’él furent sortis de Mitsrayim, 
    le premier jour du cinquième mois il mourut à l’âge de cent-vingt-trois ans sur la montagne de Hor près des frontières du pays d’Edowm et ne vit pas la terre Promise car Moshèh et lui, avaient été rebelles aux commandements du Seigneur Yehowshuw‘a ha-Mashiyah devant le peuple, aux eaux de Meriybah. Et ’El‘azar son troisième fils, lui succéda.
    
    `,
    references: [
      "Ex. 4:14",
      "Ex. 6:16-20, 28",
      "Ex. 32:1-24",
      "No. 18:1-2",
      "No. 20:23-29",
      "No. 33:38-39",
    ],
    notes: [

    ]
  },
  {
    id: `00001`,
    word: `‘AMOWS,`,
    part: `Le règne`,
    preview: `Amos`,
    origin: `(n. m.) d’origine ‘ivriyt : עָמוֹס (aw-mô-ce) : « fardeau, porteur de fardeau ».`,
    text: `De profession Berger, ‘Amows était natif de Teqow’a, ville située à 16 kilomètres de Yeruwshalaïm et à 8 kilomètres au sud de Beyt-lèchèm, donc en plein territoire de Yehuwdah et de Binyamiyn. N’étant ni prophète, ni fils de fils de prophète, il fut visité par ’Adonay YHWH lorsqu’il était derrière son troupeau (Am. 7:14-15).
    ‘Amows prophétisa sous les règnes de ‘Ouzziyyah (829-778 av. Y.-M.), mais principalement sous la monarchie de Yarov‘am, fils de Yow’ash (844-803 av. Y.-M.) dans le royaume de Yisra’él. Son livre se partage en neuf chapitres. 
    Le livre est rédigé dans un contexte où le royaume de Yisra’él a exacerbé la colère de ’Elohiym YHWH à cause de ses multiples péchés dans lesquels Yarov‘am, fils de Nevat l’a entraîné à tourner le dos à YHWH pour les faux ’èlohiym. Étant las de ses abominations, ’Adonay Yehowshuw‘a ha-Mashiyah annonce qu’il va mettre un terme à l’existence de cette nation en la déportant dans les autres nations notamment en ’Ashshuwr.
    Dans ce livre YHWH ’Elohiym, fait savoir tout d’abord combien de fois il est en colère et qu’il décide de juger à cause du péché non seulement des autres nations (Am. 1:3-15 ; 2:1-3), mais aussi de son peuple l’Etat de Yisra’él et Yehuwdah (Am. 2:4-16 ; 3:1-2 ; 5:21-26). Ce que le Seigneur désire est que son peuple se repente ses péchés et le recherche (Am. 4:6-11 ; 5:14-15) et non qu’il se tourne vers d’autres nations pour le sauver (Am. 5:4-6).
    À cause du péché de Yisra’él qui ne cesse de croître devant la face de YHWH, ’Elohiym annonce qu’il n’a pas d’autre choix que de le juger afin d’appliquer sa justice (Am. 5:12 ; 6:7-14 ; 7:8-9, 17 ; 8:2-3, 9-14 ; 9:1-5, 8-15).
    Cependant, comme YHWH ne garde pas sa colère à toujours, il annonce qu’il restaurera la maison du roi Dawid. Et tous les captifs qui auront été dispersés dans les nations seront ramenés sur leur terre pour former à nouveau le royaume de Yisra’él (Am. 9:11-15).
    
    `,
    references: [
      "Am. 7:14-15",
      "Am. 4:6-11",
      "Am. 5:21-26",
      "Am. 4:6-11",
      "Am. 5:14-15",
      "Am. 5:4-6",
      "Am. 1:3-15",
      "Am. 2:1-3",
      "Am. 5:12",
      "Am. 6:7-14",
      "Am. 7:8-9",
      "Am. 7:17",
      "Am. 8:2-3",
      "Am. 9:1-5",
      "Am. 9:8-15",
      "Am. 9:11-15"
    ],
    notes: [
      "Voir le livre éponyme."
    ]
  },
  {
    id: `00002`,
    word: `ANGLICANISME `,
    part: `Historique`,
    preview: ``,
    origin: `L’expression « Église anglicane » vient du latin médiéval ecclesia anglicana qui signifie Assemblée anglaise ou couramment nommé « église anglaise ».`,
    text: `Branche du catholicisme romain issue de la rupture survenue au XVIème siècle après Y.-M. entre le roi Henri VIII d’Angleterre et l’Eglise catholique romaine. En effet, le roi d’Angleterre veut répudier sa femme, Cathérine d’Aragon, parce qu’elle n’arrive pas à lui donner un fils, alors il fait appel au pape Clément VII pour faire annuler son mariage. Mais ce dernier refusant de le faire, Henri VIII rompt les liens avec le pape en 1531 et fait voter le divorce dans une cour ecclésiastique. Puis après la mort du pape, le roi d’Angleterre Henri VIII se fait proclamer « seul chef suprême » de l’église d’Angleterre.
    Elle a conservé la structure épiscopale c’est-à-dire l’autorité des évêques. Leur doctrine est à mi-chemin entre le protestantisme et le catholicisme duquel le roi Henri VIII s’est séparé pour pouvoir se remarier. L’eucharistie est le point central dans la liturgie.
    Aujourd’hui le nouveau chef suprême de l’église d’Angleterre est le roi Charles III.
    Cette religion est aussi sectaire que l’Eglise catholique car elle a en partage les mêmes fondements que cette dernière.
    `,

    notes: [
      "(5)Noms généralement traduit par Babylone, Médie et Perse, Grèce et Rome."
    ]
  },
  {
    id: `00003`,
    word: `BABYLONE POLITIQUE `,
    part: `Historique`,
    preview: `Voir Bavèl Politique, la`,  
  },
  {
    id: `00004`,
    word: `Bavèl Politique `,
    part: `Historique`, 
    origin: `BAVEL POLITIQUE, LA`,
    text: `Elle correspond à tout le système politique mise en place par les hommes afin de diriger les habitants de la Terre à partir du gouvernement mondial dont le dirigeant et le fondement est l’Antimashiyah. Elle institue des lois et commandements impies allant à l’encontre de la Parole d’Èlohiym tels que l’officialisation des mariages homosexuels par des « hommes dits d’église », l’acceptation du troisième genre humain ou l’incrustation de la théorie de l’évolution, et les projets de vaccination planétaire et d’autres lois drastiques pour maintenir les populations sous un contrôle absolu parce qu’elles refusent que le Seigneur et sa Parole guident leurs vies pour se laisser entraîner par les penchants de leurs cœurs.
    Et je vis l'une de ses têtes comme blessée à mort, mais sa blessure mortelle fut guérie. Et toute la Terre était dans l'admiration derrière la bête. Ap. 13:3. La bête qui monte de la mer représente à la fois le système politique et l’Antimashiyah lui-même. Cette bête fait écho à la bête que le prophète Daniy’él vit dans une vision (Da. 7:1-7). Du temps du prophète Daniy’él, chaque partie de ce bête représentait respectivement Bavèl, Maday et Paras, la Hellas et la Romè. Mais aujourd’hui toutes ces nations ont totalement disparu. La bête que l’apôtre Yowhanan n’est plus la représentation de ces quatre nations mais l’ensemble des nations sans ’Èlohiym. Les différentes parties de « la bête d’Apocalypse » décrit la personnalité de ce royaume : Le léopard représente la rapidité et la culture hellénistique. Ce système s’établira très rapidement sur toutes les nations de la Terre et imposera la culture hellénistique dont l’adoration des faux ’èlohiym notamment Satan et l’Antimashiyah. L’ours, animal agressif, témoigne le degré de violences et de répressions qu’imposera ce système pour instaurer son règne. Il détruira tous ceux qui s’opposeront à eux grâce au pouvoir judiciaire et militaire. Son règne sera bâtit sur le sang, la brutalité et la férocité. Il va instaurer le règne de la terreur. Et le Lion, représente la royauté. Il assiéra son règne sur toutes les nations et son règne sera incontestable. Il aura l’autorité sur toute la Terre (Ap. 13:7).
    Il formera un couple avec la Grande Prostituée, l’alliance Religion-Etat, pour recevoir l’adoration des habitants de la Terre ceux dont les noms n’ont pas été écrits dans le livre de vie (Ap. 17:7-13). La Grande Prostituée aussi appelée la Bavèl religieuse contraindra les humains du monde à adorer la bête et le dragon. Elle va rendre son culte licite et obligatoire (Ap. 13:3-8, 11-15). Plus tard, la Bavèl politique se retournera contre la Grande Prostituée et la détruira selon la volonté d’Elohiym Yehowshuw‘a ha-Mashiyah(Ap. 17:16-17).
    Pendant la période de la Grande Tribulation, ce système va traquer, persécuter, tuer, massacrer les croyants en Yehowshuw‘a ha-Mashiyah pendant quarante-deux mois, soit trois ans et demie (Ap. 13:5-7).
    Par ce système, l’Antimashiyah contrôlera la vie poli²tique et sociale de tous les habitants de la Terre.
    Dans la Bible, la Bavèl politique représente à la fois l’Antimashiyah et son système (Ap. 13:1-10)
    
    `,
    references: [
      "Da. 7:1-7",
      "Ap. 13:1-10",
      "Ap. 13:5-7",
      "Ap. 17:16-17",
      "Ap. 13:5-7",
      "Ap. 17:7-13",
    ],
    notes: [

    ]
  },
  {
    id: `00005`,
    word: `CALVINISME, LE`,
    part: `Historique`,
    preview: ``,
    origin: ``,
    text: `Doctrine élaborée par le réformateur français Jean Calvin au XVIème siècle après Y.-M. Puis, renchéries par Théodore de Bèze, John Knox. Selon les communautés, les partisans de cette doctrine sont nommés presbytériens, congrégationalistes, églises réformées. Elle est issue du protestantisme.
    Le point central de ladite doctrine repose sur le salut précisément sur la prédestination. Selon le réformateur français, l’homme est sauvé non parce qu’il l’a mérité mais juste à cause de la grâce d’Èlohiym : « Dieu, par lequel, selon le très libre et bon plaisir de sa volonté, par pure grâce, il a, en Jésus-Christ, élu au salut avant la fondation du monde – d’entre tout le genre humain déchu par sa propre faute de sa première intégrité dans le péché et la perdition, – une certaine multitude d’homme, ni meilleur ni plus digne que les autres, mais qui, avec ceux-ci, gisaient dans une misère ». Ainsi le salut de l’homme repose sur une présélection céleste.
    Cet angle de vue sur la prédestination remet en cause toute l’œuvre sacrificielle de notre Seigneur Yehowshuw‘a ha-Mashiyah qui veut que tous les hommes soient sauvés : « Car cela est bon et agréable devant Elohîm, notre Sauveur, qui veut que tous les humains soient sauvés et qu’ils viennent à la connaissance précise et correcte de la vérité » 1Tim. 2:3-4. Lorsque le premier couple, ’Adam et Hawwah, a péché, l’humanité est sortie du plan du Seigneur : « C’est pourquoi, comme par le moyen d’un seul être humain le péché est entré dans le monde, et par le moyen du péché la mort, de même aussi la mort s’est étendue sur tous les humains, par lequel tous péchèrent » Rom. 5:12 (Rom. 3:23).
    L’être humain étant déchu de sa position, ’Èlohiym entreprend un plan de salut pour les nations en envoyant son Fils Yehowshuw‘a ha-Mashiyah afin de rendre possible une restauration des êtres humains par la foi en son Nom : « Car Elohîm a tant aimé le monde qu’il a donné son Fils unique, afin que quiconque croit en lui ne périsse pas, mais qu’il ait la vie éternelle » Jn. 3:16. Bien que cette solution d’Èlohiym soit tendue à tous les humains, cependant plusieurs la refusent car ils ont préféré les ténèbres plus que la lumière : « Et voici le jugement : C’est que la lumière est venue dans le monde et que les humains ont aimé la ténèbre plus que la lumière, car leurs œuvres étaient mauvaises » Jn. 3:19. 
    Cette perspective biblique de la prédestination montre que la disqualification des êtres humains ne s’est pas effectuée depuis l’éternité car Èlohiym veut plutôt les sauver car l’homme est au centre de ses intérêts (Ps. 8:5 ; Hé. 2:6).
    La prédestination abordée par l’apôtre Paulos dans l’épître aux ephesios révèle le plan parfait conçu par ’Èlohiym pour tous ceux croient en Fils Yehowshuw‘a ha-Mashiyah : « Selon qu’il nous a élus en lui avant la fondation du monde, afin que nous soyons saints et sans défaut devant lui dans l’amour, nous ayant prédestinés à l’adoption pour lui-même, par le moyen de Yéhoshoua Mashiah, selon le bon plaisir de sa volonté » Ep. 1:4-5. Cependant, cette promesse de l’élection reste conditionnée par la seule persévérance du croyant en Rabbi Yehowshuw‘a ha-Mashiyah jusqu’à la fin : « Mais celui aura supporté bravement et calmement les mauvais traitements jusqu’à la fin sera sauvé » Mat. 24:13 (1Co. 9:25-27 ; Ap. 3:5).
    `,
    references: [
      "1Ti. 2:3-4",
      "Ep. 1:4-5",
      "Rom. 5:12",
      "Rom. 3:23",
      "Jn. 3:19",
      "Ps. 8:5",
      "Hé. 2:6",
      "Mt. 24:13",
      "1Co. 9:25-27",
      "Ap. 3:5",
    ],
    notes: [
      `Extrait des canons de Dordrecht, nouvelle édition réalisé par la fondation d’Entraide Chrétienne Réformée 
      aux Pays-Bas, en collaboration avec les Edition Kerygma en France, 1998.`
    ]
  },
  {
    id: `00006`,
    word: `Jan Hus [1370-1415]`,
    part: `La réforme`,
    preview: ``,
    origin: ``,
    text: `Théologien et réformateur tchèque, né au sud de la bohême (Tchèque actuelle). Ses parents étaient des paysans pas très nantis. Jan Hus fit néanmoins des études à l'université de Prague, avant d’être plus tard enseignant dans celle-ci. Il sera nommé recteur quelques années après dans la même université. Il est ordonné prêtre en 1400 et sa rencontre avec le pré-réformateur John Wiclyf influence ses enseignements. Il soutient publiquement certaines de ses thèses et prêche contre l’église romaine. Il prônait  pour un retour à l’église apostolique, spirituelle et pauvre. En outre, il dénonça ouvertement les droits et les privilèges du clergé, prononça de violentes attaques contre la cour de Rome, publia douze thèses contre la bulle pontificale et enseignait en langue vulgaire (langue du peuple). 
    En 1411, Hus fut excommunié de l’église catholique et en 1414 il sera emprisonné dans un monastère. Le concile de Constance amorcé en 1414 avant son arrestation se réunit pour trancher sur ses thèses qu’ils considèrent comme semant le trouble, lui demandant de renier ses écrits ou alors de les alléger. Mais HUS refusa et déclara : « Ces évêques m'exhortent à me rétracter et à abjurer. Mais moi je crains de le faire pour ne pas être trouvé menteur devant le Seigneur et aussi pour n'offenser ni ma conscience ni la vérité de Dieu » « Je suis prêt, à obéir au pape tant que ses ordres sont conformes à ceux des Apôtres. Mais s’ils y sont contraires, je n’y obéirai point, même si j’ai mon bûcher dressé devant moi. »  Après son refus, il fut  condamné pour hérésie et  brulé vif sur le bûcher le 6 juillet 1415. 
    Après sa mort, plusieurs Tchèques qui le considéraient comme martyr, suivirent ses enseignements et se révoltèrent contre l’empereur  germanique et la papauté. Une guerre appelée « guerre hussites » (1419-1436) éclata entre les adhérents aux idées de Jan HUS et le pouvoir de l’époque qui les considéraient comme hérétiques. Lors de cette guerre, ceux qu’ils appelaient les hussites réclamaient la communion sous deux espèces, la liberté de prêcher l’évangile, la confiscation des biens du clergé et la punition des péchés mortels. Le concile de Bâle accepte d'aménager la doctrine officielle de l’Église en accordant la communion sous les deux espèces et la lecture en tchèque de l’épitre et de l’Evangile. De même, Martin Luther l’un des plus grands réformateurs du XV s’inspira de la doctrine de Jan Hus.
    Les Tchèques ont fait de lui un héros national, le 6 juillet est commémoré par un jour férié en référence à leur combat contre l'oppression catholique, impériale et allemande.
    `,
  },
  {
    id: `00007`,
    word: `Jean CALVIN (1509 -1564)`,
    part: ``,
    preview: ``,
    origin: ``,
    text: `Le Français, Jehan Cauvin de son nom vrai nom, est né le 10 juillet 1509 en Noyon (Picardie). Son père, Gérard Cauvin était notaire à la cathédrale et tribunal ecclésiastique. Sa mère, Jeanne le Franc, était fille d'un aubergiste de Cambrai. Dès son adolescence, il commença des études théologiques et termina par des études de droit à l’université d’Orléans, puis celle de Bourges, à l’initiative de son père.
    Marqué par une époque à laquelle les idées de réformes s’affrontent avec celles de l’église romaine, Calvin se convertit à la Réforme. Il fréquente plusieurs humanistes de son temps et adhère aux idées de Luther. De ce fait, il s’oppose à certaines pratiques de l’église catholique parmi lesquelles, l’utilisation des images de Dieu, la transsubstantiation et le traitement de l’eucharistie comme sacrifice. Parallèlement, il défend la doctrine de la prédestination développée par Augustin et l'idée de la trinité. Il définit d'abord la foi comme la connaissance ferme et certaine de Dieu en Christ. Les effets immédiats de la foi sont la repentance et la rémission du péché. Il sera obligé de s’exiler à Genève après la répression du roi François Ier contre les protestants suite à l’affaire des placards. Il participa largement à la Réforme à Genève au côté d’autres réformateurs et particulièrement auprès de Guillaume Farel, Pierre Viret et de Théodore Bèze. Etant Francophone, ils recueillent et travaillent avec d’autres français en exile à Genève. L’Institution de la religion chrétienne, qui est éditée d’abord en 1536 puis en 1561, est l’une des œuvres de Calvin. Dans cette œuvre, plusieurs points doctrinaux y sont abordés, notamment celui portant sur la prédestination. Pour Calvin, Dieu est souverain et la gratuité du salut lui incombe, c’est lui-même qui choisit les élus avant leur naissance.
    Calvin meurt en 1564 et laisse derrière lui des idées qui ont donné naissance à l’église presbytérienne et ont largement contribué à la Réforme protestante en général. Le réformateur contemporain de Calvin tel que John Konx, reprit énergiquement les thèses de ce dernier. La dénomination du calvinisme, de l’église réformée ou la foi réformée est bâtie principalement sur les enseignements de Jean Calvin avec une mise en avant de la doctrine de la prédestination. Calvin participa aussi à la révision à la version française de la Bible traduite par Pierre Robert Oliviétan.  `,

    notes: [
      "1 En octobre 1534, précisément la nuit du 18, des placards (affiches) contre les pratiques catholiques sont apposés sur mûrs de grandes villes de France, ainsi que devant la porte de la chambre du roi de l’époque, François 1er. Ces placards traitent les rites de la messe catholique de sorcellerie et accusent le pape, les évêques, les prêtres et les moines de mensonge et de blasphème. ",
      `²Le calvinisme est une doctrine théologique protestante qui repose sur le principe de la souveraineté de Dieu en toutes choses. Ainsi, Dieu aurait choisi ses élus dans sa prescience et il aurait également choisi ceux qui seront damnés.
      `,
      "3 La Bible de Genève.",
      "4  Cette thèse l’opposait au réformateur Zwingli",

    ]
  },
  {
    id: `00008`,
    word: `Martin Luther [1483-1546]`,
    part: `La réforme`,
    preview: ``,
    origin: ``,
    text: `Théologien, écrivain allemand et initiateur de la Réforme, Martin Luther est né en 1483 à Eisleben. Il est le fils aîné de Hans Luder et de Marguerite Zidler. Son père, paysan à la base devient un exploitant minier. Cette ascension permet à la famille de rentrer dans la bourgeoisie.  Destiné à faire des études de droit, il les abandonne, côtoie la philosophie et se focalise finalement sur la théologie pour s’aligner avec ses convictions religieuses. Il sera ordonné prêtre en 1507 mais il ne tardera pas à s’insurger contre les dogmes de l’église catholique. En effet, malgré sa vie ecclésiastique, Luther avaient des interrogations sans réponse sur le salut qui le préoccupaient énormément. Son étude de l’épitre de Paul aux Romains marque un pan important dans sa vie, il comprend désormais que le salut est un don de Dieu qui s’obtient par la foi. Il remarque en outre que l’organisation de l’église catholique et ses croyances ne sont pas biblique. Alors, en 1517, il publia 95 thèses dans lesquelles il dénonce le pouvoir du pape, critique les indulgences et refuse la théologie des œuvres. Pour soutenir ses critiques, il affirme que la parole de Dieu est supérieure à l’autorité du pape, le salut s’obtient par la foi qui est un don de Dieu, conformément aux épitres de Paul, et il voyait le pouvoir divisé en deux parties : celui temporel (le roi sur Terre)  et celui éternel (Dieu au ciel). 
    De plus, contemporain d’une période à laquelle la Bible n’est qu’à la disposition du clergé et certains universitaires, Luther obtient la possibilité de la lire lors de ces nombreux passages 
    à la bibliothèque de son université de Wittenberg. Découvrant les vérités bibliques, il entreprend de la traduire en allemand accessible à tous et la remet aux mains du peuple. Puis il institue les réformes du culte et considère la prédication et l’enseignement de la parole de Dieu comme étant la partie la plus importante du culte. Il  publia plusieurs thèses dans lesquelles il partagea sa liturgie, ce qui lui valut d’être excommunié par le pape Léon X qui considérait ses réformes hérétiques. Ce dernier avait émis la bulle pontificale qui condamnait Luther pour ses positions contre l’Église romaine exprimées dans ses écrits. Heureusement pour Luther qui eut le soutien du prince Frédéric III le Sage qui brûla publiquement la bulle papale.
    Le vent de la réforme impulsé par Luther lui rendit trop populaire que, les autorités ecclésiastiques ne pouvaient rien contre lui. Charles Quint, empereur germanique, ordonna sa comparution à la diète tenue à Worms tenu en 1521 afin qu’il retire sa doctrine, Luther se rendit à cette diète mais resta sur ses idées. En conséquence, ses livres furent condamnés à être brûlés pour cause d’hérésie. Mais il ne fut pas arrêté comme d’autres réformateurs, ni tué car sa popularité était considérable. 
    Aussi, les idées luthériennes ne sont pas seulement combattues par les catholiques, plusieur réformateurs de son temps y font également partie. Zwingli, un grand réformateur du temps de Luther, s’opposait à sa position sur la transsubstantiation dans l’eucharistie. Et avec Zwingli, plusieurs autres réformateurs se séparaient de Luther à cause ce désaccord. Cette discorde créant des troubles chez les adeptes de la Réforme, les hommes se rencontrèrent lors du colloque Marburg (1529) pour éclaircir plusieurs points de discorde et en particulier sur celui de l’eucharistie. Mais sur ce dernier point, ils ne parvinrent malheureusement pas à s’accorder.
    Luther meurt dans sa ville natale le 18 février 1546, ces idées ont apporté une grande influence sur la réforme protestante. Il est reconnu comme étant le père du protestantisme. Ainsi, Plusieurs des premiers protestants étaient appelés luthériens et la grande majorité des réformateurs ont été inspirés par ses thèses. Les rassemblements chrétiens soutenant les idéologies de Luther étaient désormais qualifiés d’églises luthériennes. Il s’agit à cet instant d’une branche à part entière du protestantisme, ces églises existent encore de nos jours. 
    `,
  },
  {
    id: `00009`,
    word: `MAL’AKHIY`,
    part: `Le règne`,
    preview: ``,
    origin: `(n. m.) d’origine ‘ivriyt : מַלְאָכִי (mal-a-rî) : « mon messager ».`,
    text: `Prophète d’Èlohiym appartenant à l’époque de la reconstruction du Temple de Shlomoh, fils de Dawid, roi de Yisra’él. Il exerça son ministère sur la Terre de Yehuwdah et fut un contemporain du gouverneur Zeroubavèl. Le livre dont il est l’auteur, a été rédigé au Vème siècle avant Y.-M. Dans l’ordre de classement, ce livre, dans la majorité des versions, est le dernier de l’Ancienne Alliance. A contrario, dans la Bible ‘Ivriyt, il clôture l’une des parties de l’acronyme Tanakh appelée Neviy’iym. Son livre se partage en trois chapitres.
    Le livre est écris dans un contexte où, le service léwiytes ayant été restauré. Cependant, les dépositaires affichaient un total mépris à l’autel de YHWH et avaient beaucoup d’inconsidération pour celui dont le Nom est craint dans toutes les nations. Cet ouvrage, est un dialogue entre ’Èlohiym Yehowshuw‘a ha-Mashiyah et son peuple.
    Le chapitre premier s’ouvre par un rappel de l’amour de YHWH pour son peuple : « je vous ai aimés, dit YHWH. Et vous dites : En quoi nous as-tu aimés ? Ésav n’était-il pas le frère de Yaacov ? – déclaration de YHWH. Or j’ai aimé Yaacov » (Mal. 1:2). Rien de fortuit, d’entrée de jeu, ce rappel témoigne de l’amour permanent et indéfectible d’Èlohiym YHWH pour les enfants de Yisra’él. Ces quelques phrases signifient qu’en dépit de leurs erreurs, il les 
    aimera toujours. Toutefois, cela n’empêche pas la correction du Seigneur, comme un Père qui corrige son fils. En effet, les prêtres multipliaient des actions mauvaises et déshonorantes à son endroit en apportant sur l’autel d’airain des offrandes corrompues, non recommandées par la Towrah de Moshèh : « Vous dites aussi : Quelle fatigue ! Et vous soufflez dessus, dit YHWH Sabaoth. Vous amenez ce qui a été dérobé, ce qui est boiteux, et malade, ce sont là les offrandes que vous faites ! Accepterai-je cela de vos mains ? dit YHWH » (Mal. 1:13).
Aux offrandes corrompues, il faut ajouter les dîmes et les offrandes que les prêtres volaient en les apportant plus au Seigneur dans les chambres du trésor du Temple de YHWH (Mal. 3:7-10).
Parallèlement aux mauvaises actions des prêtres, ils répudiaient leurs femmes, choses contre laquelle ’Èlohiym YHWH est foncièrement contre : « Et vous dites : Pourquoi ? C’est parce que YHWH a été témoin entre toi et la femme de ta jeunesse, contre laquelle tu agis avec tromperie. Et pourtant, elle est ta compagne et la femme de ton alliance. » (Mal. 2:14). Aussi, se servaient-ils de la Towrah pour détourner plusieurs de la foi en ’Èlohiym YHWH (Mal. 2:8).
Toutes ces actions dont nous fait état le prophète, témoigne du recul des valeurs morales et l’abandon de la Towrah de Moshèh.
Et à toutes ces méconduites et aux actes irrévérencieux, le Seigneur répond par des reproches sévères proportionnels à leurs mépris : « C’est pourquoi, maudit soit l’homme trompeur, qui a dans son troupeau un mâle, et qui voue et sacrifie à Adonaï ce qui est corrompue ! Car je suis le Grand Roi, dit YHWH Sabaoth, et mon Nom est redoutable parmi les nations » (Mal. 1:14). 
Plus tard dans le troisième chapitre, le prophète Mal’akhiy annonce la venue du messager de l’alliance, le prophète ’Eliyahuw, plein d’autorité, pour purifier les fils Léwiy et de dénoncer les méchants oppriment les nécessiteux (Mal. 3:1-6). Et pour ramener les cœurs des pères vers les fils, et ceux des fils vers les pères (Mal. 3:23-24).
’Adonay ’Èlohiym adresse une exhortation à ses serviteurs de revenir à lui en rapportant toutes les dîmes et les offrandes qu’ils détournaient en leurs faveurs (Mal. 3:7-15). Tout en annonçant qu’il se gardera un reste qui lui demeurera fidèle (Mal. 3:16-18).
Enfin, comme d’autres prophètes à l’instar de Yesha‘yahuw, Yehèzqé’l, Yow’él, ‘Amows, ‘Ovadyah, Tsefanyah, Zekaryah, le prophète Mal’akhiy annonce l’avènement du « Jour de YHWH ». Ses prédécesseurs comme ses contemporains, tous décrivent ce jour comme un temps cruel, un temps de jugement contre tous ses ennemis : « Le grand jour de YHWH est proche, il est proche, il arrive très vite. Le jour de YHWH fait entendre sa voix, et les hommes vaillants y pousseront des cris amers. Ce jour est un jour de fureur, un jour de détresse et d’angoisse, un jour de bruit éclatant et effrayant, un jour de ténèbres et d’obscurité, un jour de nuées et de profonde obscurité, un jour de shofar et d’alarme de guerre contre les villes fortifiées, et contre les hautes tours » (So. 1:14-16). 
Ce sera un moment de terreur, d’effroi et de peur pour ses adversaires. Un jour unique qu’il s’est réservé pour se venger et anéantir tous ses ennemis qui ont osé se dressés contre lui, le Roi de tous les Cieux et contre son peuple. A l’inverse, ce sera un moment de grande réjouissance et d’allégresse pour son peuple, les enfants de Yisra’él, car ’Èlohiym Yehowshuw‘a ha-Mashiyah est sera venu pour eux.

`, references: [
      "Mal. 1:2",
      "Mal. 1:13",
      "Mal. 2:8",
    ],
    notes: [
      " Aussi nommé ‘Azaryah.",
    ]
  },
  {
    id: `00010`,
    word: `TSEPHANYAH`,
    part: `Le règne`,
    preview: `Sophonie`,
    origin: `(n. pr. m.) d’origine ‘ivriyt : צְפַנְיָה (tsә-fan-yaw’) : « Yah a caché, protégé ».
    `,
    text: ``,
    references: [
      "So. 1:7, 14-16",
      "So. 2:1-3",
      "So. 3:9-10",
    ]
  },
  {
    id: `00011`,
    word: `YESHA‘YAHUW`,
    part: `Le règne`,
    preview: ``,
    origin: `(n. m.) d’origine ‘ivriyt : יְשַׁעְיָהוּ (yә-sha-ya-hou) ou YESHA‘YAH, (n. m.) d’origine ‘ivriyt : יְשַׁעְיָה (yә-sha-ya) : « Salut, délivrance de Yahuw [YHWH] ».
    `,
    text: `Fils d’Amowts, le prophète de יהוה exerça son service en Yehuwdah du temps ‘Ouzziyahuw jusqu’au règne de Yehizqiyyahuw, roi de l’Etat de Yehuwdah pendant plus d’un siècle.
    Avec les prophètes Yirmeyahuw et Yehèzqé’l, ils sont baptisés « prophètes majeurs » à cause de la longueur de leurs textes. A contrario les autres prophètes sont nommés « prophètes mineurs ». 
    La particularité de Yesha‘yahuw par rapport à ses paires en Yisra’él, c’est parce qu’il a annoncé et donné plus de détails sur la personne du Mashiyah, Yehowshuw‘a, le roi de Yisra’él, sur sa naissance (Es. 7:14 ; 9:5-6), service (Es. 11:1-10), sa passion (Es. 53:1-12), sa divinité (Es. 35:4), sa mort (Es. 53:8) et sa résurrection (Es. 53:10) plus que n’importe quels autres prophètes. Il est le prophète messianique.
    Le prophète commence sa prophétie par ses paroles : « Cieux, écoutez ! Et toi, Terre, prêtre l’oreille ! Car YHWH parle […] » (Es. 1:2). Par cette proclamation, forte de sens et pleine d’autorité, ’Èlohiym יהוה ordonne à l’ensemble de son peuple d’écouter ce qu’il a à dire car leurs oreilles de ce peuple se sont bouchées. Des paroles de vie pour le salut des enfants de Yisra’él. Des Paroles qui s’adressent à un peuple dont devenue dure d’entendement. Le discours du prophète s’inscrit dans un contexte où les enfants de Yisra’él ne veulent plus entendre la Parole d’Èlohiym car ils n’ont de plaisir que pour les faux ’èlohiym. Cette attitude s’est traduite par une apostasie généralisée, de la tête jusqu’à la plante du pied. C’est un cancer au troisième stade de son évolution qui a paralysé tout le corps dont l’espérance de vie est très mince. Cependant, c’est vers ce peuple, les enfants de Yisra’él, que Yesha‘yahuw, fils d’Amowts fut envoyé pour annoncer les oracles célestes car ce peuple est celui de la promesse.
    Son livre, écrit au VIIIème siècle avant Y.-M., est divisé en soixante-six chapitres. Et selon la tradition Yesha‘yahuw, fils d’Amowts serait l’auteur de ce livre.
    Le prophète reçu l’appel du Seigneur lors d’une visitation d’Èlohiym יהוה où il fut transporté en esprit dans le Temple céleste où il vit la Gloire d’Èlohiym pleine de majesté. Une révélation tellement glorieuse que le prophète s’est senti impur devant le Maître de l’Univers et s’est fut purifié de ses péchés. C’est à ce moment qu’il reçut l’appel d’aller annoncer la Parole d’Èlohiym vers son peuple (Es. 6:1-13).
    Sur plus d’un siècle de service, Yesha‘yahuw, esclave de יהוה a annoncé des prophéties pluri-thématiques dont les destinataires étaient exclusivement les nations païennes voisines et le royaume de Yehuwdah. En ce qui concerne les ennemis de Yisra’él, le prophète a annoncé des Paroles dures de la part Yehowshuw‘a ha-Mashiyah empreintes de jugement à cause de tout le mal qu’elles ont causé à Yisra’él à l’instar du royaume d’Ashshuwr (Es. 10:5-19 ; 14:24-27 ; 30:27-33), le royaume de Bavèl (Es. 13:6-16 ; 14:18-23 ; 21:1-10 ; 47:1-15), le royaume des Maday et des Paras (Es. 13:17-22), les pelishtiym (14:28-32), le royaume de Mow’av (Es. 15-16), le royaume de Dammasèq (Es. 17:1-14), le royaume de Kuwsh (Es. 18:1-7 ; 20:1-6), le royaume de Mitsrayim (Es. 19:1-15 ; 20:1-6), l’Arabie (Es. 21:13-17), le royaume de Tsor (Es. 23:1-18). Et comme il est dit que sa Parole ne retourne pas à lui sans pourtant accomplir sa volonté. Ainsi, toutes ses nations ont été exterminées conformément aux prophéties annoncées ultérieurement.
    Par ailleurs, des valeurs morales dégradantes, délaissement de la Towrah et abandon l’Èlohiym de Yisra’él, Yehowshuw‘a ha-Mashiyah, le prophète dépeint un tableau sombre de sa nation qui ne cesse de s’enfoncer dans les ténèbres (Es. 1:1-15 ; 5:8-30 ; 44:9-20 ; 57:1-11). Cependant, au cœur de l’obscurité le Seigneur יהוה annonce des prophéties pleines d’espoir, le retour des dispersés sur leur Terre (Es. 11:11-12 ; 56:8), renaissance du royaume de Yisra’él (Es. 66:7-9) puis le rétablissement du royaume de Dawid (Es. 2:1-5 ; 11:1-10 ; 62:1-12).
    De tous les prophètes, il est celui dont les Paroles ont beaucoup été reprises dans les livres de la Nouvelle Alliance à l’instar de cette célèbre et merveilleuse assertion qui annonce la naissance de Yehowshuw‘a ha-Mashiyah, l’Èlohiym de tous les temps et de tous les âges, par le moyen d’une femme vierge : « Voici, la vierge aura dans son ventre et enfantera un fils, et ils l’appelleront du Nom d’Immanou-El, ce qui, interprété, est : El avec nous » (Mat. 1:23). Ce passage fait écho à celui de Yesha‘yahuw 7:14.`,
    references: [
      "Es. 7:14", "Es. 9:5-6",
      "Es. 11:1-10",
      "Es. 2:1-5",
      "Es. 35:4",
      "Es. 53:8",
    ]
  }, 
  {
    id: `00005`,
    word: `YIRMEYAHUW`,
    part: `Le règne`,
    preview: `Jérémie`,
    origin: `(n. m.) d’origine ‘ivriyt : יִרְמְיָהוּ (yir-me-ya-hou) ou יִרְמְיָ (yir-me-yah) : « Yah [YHWH] a désigné », « élevé par Yah [YHWH] ».
    `,
    text: `
    Fils de Hilqiyahuw et descendant d’Aharown car il était de la famille des prêtres qui demeuraient à ‘Anatowth de Binyamiyn (Jér. 1:1). Yirmeyahuw était prophète de יהוה qui exerça principalement son service dans le royaume de Yehuwdah du temps de Yo’shiyahuw, roi de Yehuwdah, à partir de la treizième année de son règne jusqu’à la déportation du royaume des yehuwdiym à Bavèl par le roi Nevukadnè’tstsar, puis en Mitsrayim. Il prophétisa pendant plus de quarante ans.
Dans le premier chapitre de son livre, le Seigneur Yehowshuw‘a ha-Mashiyah lui pose une question : « La parole de YHWH vint à moi, en disant : Que vois-tu, Yirmeyah ? Et je répondis : je vois une branche d’amandier. YHWH me dit : Tu as bien vu, car je veille sur ma parole pour l’exécuter » (Jér. 1:11). A cette question, s’ajoute une deuxième : « La parole de YHWH vint à moi pour la seconde fois, en disant : Que vois-tu ? Et je répondis : Je vois un pot bouillant dont la face est tournée vers le nord. Et YHWH me dit : Le mal se découvrira du côté du nord sur tous les habitants de la terre » Jér. 1:13-14.
Le bref échange entre le Seigneur et son serviteur, est la résultante des multiples péchés du royaume de Yehuwdah à son égard. En effet, deux décennies, Menashshèh, fils de Hizqiyyahuw, roi de Yehuwdah, avait à nouveau fait de Yehuwdah et de Yeruwshalaïm un centre d’idolâtrie à tel enseigne qu’il fit pire que les nations que יהוה avait chassé devant les enfants de Yisra’él (2Ro. 21:11-12 ; 2Ch. 33:9). Cette situation avait avivé le colère d’Èlohiym qu’il résolut de raser le royaume de Yehuwdah, de détruire le Temple dédié à son nom et d’exiler son peuple : « C’est seulement par l’ordre de YHWH que cela arriva contre Yéhouda, afin de l’ôter de devant sa face, à cause de tous les péchés commis par Menashè, et à cause aussi du sang innocent dont il avait rempli Yeroushalaim. Et YHWH ne voulut pas lui pardonner » (2Ro. 24:3-4 ; 23:26-27). 
Par ses questions, ’Adonay יהוה annonçait à Yirmeyahuw, le prophète, que ses jugements sur son peuple sont en cours d’exécution et viendront du nord c’est-à-dire de Bavèl par le moyen de son roi Nevukadnè’tstsar qui déportera les enfants de Yisra’él sur sa terre pour soixante-dix ans à cause des nombreux péchés de Menashshèh.
Ainsi, le Seigneur a-t-il suscité le prophète Yirmeyahuw en vue de rappeler aux yehuwdiym que ses jugements, qui sont sans remède, sont en marche.
Bien que les jugements d’Èlohiym יהוה étaient suspendus sur la nation de Yehuwdah (Jér. 1:15-16 ; 4:4-9 ; 5:15-17 ; 15:1-14 ), le Seigneur recherchait chez son peuple un cœur repentant, qu’il abandonne ses mauvaises voies pour que le Seigneur revienne de l’ardeur de sa colère (Jér. 2:31-33 ; 3:6-19 ; 18:11 ; 36:1-3, 7). Mais le peuple le résista, durci son cœur et continua à s’enliser dans le péché (Jér. 2:8, 19-29 ; 3:6, 13 ; 7:5-9, 18 ; 10:1-8 ; 11:8-10 ; 19:4-5 ; Ez. 8:5-18 ; 9:6).
Devant toutes les interpellations d’Èlohiym Yehowshuw‘a-Mashiyah, le roi Tsidqiyahuw demeura indifférent (Jér. 36:21-28), refusa de se plier à la volonté de יהוה, préféra solliciter le secours de Mitsrayim contre le roi de Bavèl (Jér. 37:7, 11), et d’écouter les conseils de ses généraux (Jér. 38:21-22).
La situation étant devenue insoluble à cause du cœur impénitent du roi, ’Èlohiym décida de livrer la nation de Yehuwdah à la combustion par les flammes, le Temple de Shlomoh à la destruction (2Ro. 25:8-10 ; Jér. 39:8 ; 52:1-14), la ville au pillage (2Ro. 20:12-17 ; 2Ro. 24:10-13 ; 2Ro. 25:14-17 ; Es. 39:1-6 ; Jér. 52:17-23 ; Ab. 1:13), et le peuple à la déportation (2Ro. 20:18 ; 2Ro. 24:14-16 ; 2Ro. 25:11, 21 ; Es. 39:7 ; Jér. 39:9 ; 52:15, 28-30).
Yirmeyahuw prophétisa aussi sur plusieurs nations annonçant les jugements d’Èlohiym יהוה à l’instar de Mitsrayim (Jér. 46:1-28), des Pelishtiym (Jér. 47:1-7), de Mow’av (Jér. 48:1-47), 
de ‘Ammown (Jér. 49:1-6), d’Èdowm (Jér. 49:7-22), de Dammasèq (Jér. 49:23-27), de Qédar (Jér. 49:28-33), de ‘Éylam (Jér. 49:34-39), et de Bavèl (50-51).Il annonça le début du temps des nations (Jer. 27:5-8).
Le livre, dont il est l’auteur, se partage en cinquante-deux chapitres et fut écrit VIème avant Y.-M.
   `,
    references: [
      "Jér. 49:1-6",
      "Ep. 1:4-5",
      "Rom. 5:12",
      "Rom. 3:23",
      "Jn. 3:19",
      "Ps. 8:5",
      "Hé. 2:6",
      "Mt. 24:13",
      "1Co. 9:25-27",
      "Ap. 3:5",
    ],
    notes: [
      `Le livre du prophète Yirmeyahuw peut être lu en parallèle avec le livre du prophète Yehèzqé’l (Ezéchiel) pour une meilleure compréhension. En effet, étant à Bavèl ’Èlohiym montra au prophète Yehèzqé’l toutes les abominations qui faisaient en Yehuwdah.`
    ]
  } 
]