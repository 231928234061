import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function DemoZoneSkeleton() {
  return (
    <div className="demo">
      <div className="demo__nav">
        <p className="demo__nav-filter">
          <Skeleton />
        </p>
        <ul className="demo__nav-words">
          {true &&
            Array(10).map(({ key }) => (
              <li key={key}>
                <Skeleton />
              </li>
            ))}
        </ul>
      </div>
      <div className="demo__word">
        <div>
          <br />
          <Skeleton />
          <br />
          <Skeleton count={5} />
          <br />
          <Skeleton />
          <br />
          <Skeleton count={24} />
        </div>
      </div>
      <div className="demo__bible">
        <p className="demo__nav-filter">
          <button className="__active">Bible</button>
          <button className="__nextly">Mes notes</button>
        </p>
        <div className="demo__bible-reference">
          <p className="demo__bible-book">{<Skeleton />}</p>
          {true &&
            Array(5).map(({ key }) => (
              <div key={key} className="demo__bible-versets">
                <p className="demo__bible-vers">{key}</p>
                <p className="demo__bible-word">{<Skeleton count={3} />}</p>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default DemoZoneSkeleton;
