import React, { useState } from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home, Faq, Contact,Demo, NoPage } from './pages'

export const ThemeContext = React.createContext<[boolean, Function]>([
  false,
  () => { }
])

function App() {
  const [dark, setDark] = useState<boolean>(false)

  return (
    <div className={`${dark ? 'dark' : 'light'}`}>
      <ThemeContext.Provider value={[dark, setDark]}>
        <BrowserRouter>
          <Routes>
            <Route>
              <Route index element={<Home />} />
              <Route path="faq" element={<Faq />} />
              <Route path="contact" element={<Contact />} />
              <Route path="demo" element={<Demo />} />
              <Route path="*" element={<NoPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeContext.Provider>
    </div>
  )
}

export default App
