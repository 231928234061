import React, { useState } from "react";
import "./ContactForm.sass";
import { useForm, SubmitHandler } from "react-hook-form";

interface ContactFormProps {
  contact: {
    icon?: string;
    title?: string;
    subtitle?: string;
    name?: {
      label?: string;
      placeholder?: string;
    };
    email?: {
      label?: string;
      placeholder?: string;
    };
    topic?: {
      label?: string;
      item: string[];
    };
    message?: {
      label?: string; 
      placeholder?: string;
    };
    btn?: {
      text?: string;
      link?: string;
    };
  };
}
type Inputs = {
  name?: string;
  email?: string;
  topic?: string;
  message?: string;
};

interface IResponse {
  msg?: string;
  status?: number | null;
}

function ContactForm(props: ContactFormProps) {
  const [response, setResponse] = useState<IResponse>({
    msg: "",
    status: null,
  });
  const { name, email, topic, message, btn } = props.contact;
  const {
    register,
    handleSubmit,
    reset,
    formState,
    formState: { isSubmitSuccessful },
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    setResponse({
      msg: "Veuillez patienter ...",
      status: 200,
    });
    fetch("https://dico-mailer.yaqar.org/api/", {
      method: "POST", // or 'PUT'
      mode: "no-cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // inc
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response)
      .then((data) => {
        setResponse({
          msg: "Nous vons bien reçu votre message, nous y donnerons suite très prochainement. Soyez bénis.",
          status: 200,
        });
      })
      .then(() => {
        setTimeout(() => {
          setResponse({
            msg: "",
            status: null,
          });
        }, 10000);
      })
      .then(() => {
        setTimeout(() => {
          setResponse({
            msg: "",
            status: null,
          });
        }, 10000);
      })
      .catch((error) => {
        setResponse({
          msg: "",
          status: null,
        });
      });
  };

  // console.log(watch("name"), watch("email")); // watch input value by passing the name of it
  React.useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({ email: "", topic: "", message: "", name: "" });
    }
  }, [formState, reset]);
  return (
    <form className="contact" onSubmit={handleSubmit(onSubmit)}>
      <div className="contact__input-bloc  ">
        {name && (
          <p className="contact__input-group first:mr-8">
            <label htmlFor="name">{name?.label}</label>
            <input
              className={`input ${errors.name && "__error"}`}
              id="name"
              type="text"
              placeholder={`${name?.placeholder}`}
              {...register("name", { required: true, maxLength: 100 })}
            />
            {errors.name && (
              <span className="__error">
                Vous devez renseigner un nom valide
              </span>
            )}
          </p>
        )}
        {email && (
          <p className="contact__input-group">
            <label htmlFor="name">{email?.label}</label>
            <input
              className={`input ${errors.name && "__error"}`}
              id="email"
              type="email"
              placeholder={`${email?.placeholder}`}
              {...register("email", {
                required: "Vous devez renseigner une adresse mail valide",
                pattern: /\S+@\S+\.\S+/,
              })}
            />
            {errors.email && (
              <span className="__error">{errors.email?.message}</span>
            )}
          </p>
        )}
      </div>
      <div className="contact__input-bloc  ">
        {topic && (
          <p className="contact__input-group">
            <label htmlFor="name">{topic?.label}</label>
            <select
              className={`input ${errors.name && "__error"}`}
              id=""
              {...register("topic", {
                required:
                  "Vous devez choisir le sujet auquel se rapport votre message",
                minLength: 1,
              })}
            >
              {topic.item?.map((topic, index) => (
                <option value={topic} key={index}>
                  {topic}
                </option>
              ))}
            </select>
            {errors.topic && (
              <span className="__error">{errors.topic?.message}</span>
            )}
          </p>
        )}
      </div>
      <div className="contact__input-bloc  ">
        {message && (
          <p className="contact__input-group">
            <label htmlFor="name">{message?.label}</label>

            <textarea
              className={`input ${errors.name && "__error"}`}
              id="message"
              cols={30}
              rows={10}
              {...register("message", {
                required: true,
                maxLength: 2000,
                minLength: 7,
              })}
            >
              {" "}
            </textarea>
            {errors.message && (
              <span className="__error">
                Veuillez saisir un message valide entre 7 et 2000 caractères{" "}
              </span>
            )}
          </p>
        )}
      </div>
      <div className="contact__input-bloc"> 
        {response.status === 200 && (
          <p className="contact__input-group text-green-600 text-xl">
            {response.msg}
          </p>
        )}
      </div>
      <div className="contact__input-bloc">
        <p className="text-4xl"></p>
        {btn && (
          <p className="contact__input-group">
            <input
              value={btn?.text ?? "Valider"}
              className={
                response.status === 100
                  ? `btn btn-main`
                  : `disabled btn btn-main`
              }
              name="message"
              id="message"
              type="submit"
            />
          </p>
        )}
      </div>
    </form>
  );
}

export default ContactForm;
