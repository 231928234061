import React, { useState, useEffect } from "react";

import { DemoDatas } from "../../model/DemoDatas";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import DemoZoneSkeleton from "./DemoZoneSkeleton";
import { log } from "console";

import {
  DemoZoneProps,
  DemoVersTypes,
  DemoWordTypes,
} from "../../types/AppTypes";

import { useDemo } from "../../hooks/DemoHooks/useDemo";

function DemoZone(props: DemoZoneProps) {
  //* Start -> Local states

  const [word, SetWord] = useState<DemoWordTypes | undefined>(undefined);
  const [ref, SetRef] = useState<string>("");
  const [reference, SetReference] = useState<
    [string, DemoVersTypes][] | undefined
  >(undefined);

  // !End -> Local states

  //* Start -> use Hooks

  const { __active, __scribe } = useDemo();

  //! End -> use Hooks

  //* Start -> Traitments

  const getWord = async (event: React.MouseEvent<HTMLLIElement>) => {
    const wordFound = await DemoDatas.find(
      (e) => e.word === event.currentTarget.textContent
    );
    console.log(wordFound);

    wordFound && SetWord(wordFound);
  };

  const getReference = async (
    verset: string,
    event?: React.MouseEvent<HTMLLIElement>
  ) => {
    console.log(event?.currentTarget);
    const currentLi = event?.currentTarget;
    document.querySelectorAll(".demo__bible-item-li").forEach((li, key) => {
      li.classList.remove("__active");
    });
    currentLi?.classList.toggle("__active");
    SetRef(verset);
    const url = `https://bym-api.vercel.app/bym/${__scribe(verset)}`;
    const references = await fetch(url)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    SetReference(Object.entries(references));
  };

  const autoReference = async () => {
    const wd = word?.references?.find((x) => x !== undefined);
    if (wd) {
      SetRef(wd as string);
      await getReference(wd as string);
      setTimeout(() => {
        __active();
      }, 10);
    }
  };

  //! End -> Traitments

  //* Start -> Effects

  useEffect(() => {
    autoReference();
  }, [word]);

  useEffect(() => {
    SetWord({
      id: `00001`,
      word: `'Aharown`,
      part: `Le règne`,
      preview: `Aaron`,
      origin: `’AHAROWN, (n. m.) d’origine ‘ivriyt : אַהֲרוֹן (a-ha-rône’) : « haut placé » ou « éclairé ».`,
      text: `Premier fils de ‘Amram et de Yowkèvèd, descendant de Léwiy. Petit-frère de Myriam et frère aîné de Moshèh (Ex. 7:7) dont il fut le porte-parole devant Par’oh[1] et souvent devant le peuple de Yisra’él. ’Aharown fut mis à part par יהוה pour être sanctifié. Il fut le premier grand-prêtre de Yisra’él assigné au sacerdoce. Ainsi tous les prêtres en service dans le Tente de la Rencontre ou dans le Temple de Shlomoh devaient être descendants de la maison (ou de la famille) d’Aharown selon l’ordre de YHWH (Ex. 30:30 ; 40:13-15 ; No. 18:1, 7). Il fit un veau d’or par lequel Yisra’él pécha devant יהוה pendant que Moshèh recevait les instructions d’Èlohiym sur la montagne. Plus tard, à la quarantième année après que les enfants de Yisra’él furent sortis de Mitsrayim, le premier jour du cinquième mois il mourut à l’âge de cent-vingt-trois ans sur la montagne de Hor près des frontières du pays d’Edowm et ne vit pas la terre Promise car Moshèh et lui, avaient été rebelles aux commandements du Seigneur Yehowshuw‘a ha-Mashiyah devant le peuple, aux eaux de Meriybah. Et ’El‘azar son troisième fils, lui succéda.`,
      references: [
        "Ex. 4:14",
        "Ex. 6:16-20, 28",
        "Ex. 32:1-24",
        "No. 18:1-2",
        "No. 20:23-29",
        "No. 33:38-39",
      ],
    });
  }, []);

  //! End -> Effects

  return (
    <>
      {!DemoDatas ? (
        <DemoZoneSkeleton />
      ) : (
        <div className="demo">
          <div className="demo__nav">
            <p className="demo__nav-filter">
              <button className="__nextly">Parties</button>
              <button className="__active">Mots</button>
            </p>
            <ul className="demo__nav-words">
              {DemoDatas &&
                DemoDatas.map(({ id, word }) => (
                  <li key={id + "-" + word} onClick={getWord}>
                    {word}
                  </li>
                ))}
            </ul>
          </div>
          <div className="demo__word">
            {word ? (
              <div>
                <p className="demo__word-tag">{word?.part}</p>
                <p className="demo__word-word">{word?.word}</p>
                {word?.preview && (
                  <p className="demo__word-see">
                    Anciennement : {word?.preview}
                  </p>
                )}
                {word?.origin && (
                  <p className="demo__word-origin"> {word?.origin}</p>
                )}
                {word?.text && (
                  <p className="demo__word-details">{word?.text}</p>
                )}
                {word?.references && (
                  <ul className="demo__word-references">
                    {word?.references &&
                      word.references.map((vers, key) => (
                        <li key={key} onClick={(e) => getReference(vers)}>
                          {vers}
                        </li>
                      ))}
                  </ul>
                )}
              </div>
            ) : (
              <div>
                <br />
                <Skeleton />
                <br />
                <Skeleton count={5} />
                <br />
                <Skeleton />
                <br />
                <Skeleton count={24} />
              </div>
            )}
          </div>
          <div className="demo__bible">
            <p className="demo__nav-filter">
              <button className="__active">Références</button>
              <button className="__nextly">Mes notes</button>
            </p>

            {reference && (
              <div className="demo__bible-reference">
                {word?.references && (
                  <ul className=" demo__bible-item  ">
                    {word?.references &&
                      word.references.map((vers, key) => (
                        <li
                          key={key}
                          onClick={(e) => getReference(vers, e)}
                          className="demo__bible-item-li"
                        >
                          {vers}
                        </li>
                      ))}
                  </ul>
                )}

                {word?.references &&
                  reference.map((verset, index) => (
                    <div key={index} className="demo__bible-versets">
                      <p className="demo__bible-vers">{verset[1].verset}</p>
                      <p className="demo__bible-word">{verset[1].ecrit}</p>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default DemoZone;
