import React from 'react';
import './DefinitionZone.sass';

interface DefinitionZoneProps {
    test?:string;
}

function DefinitionZone(props:DefinitionZoneProps) {
    return (
        <div>
            DefinitionZone here...
        </div>
    );
}

export default DefinitionZone;