import React from 'react'
import './Footer.sass'
import LaGrandeMoisson from '../../assets/img/LaGrandeMoisson.png'
interface FooterProps {
    footer: {
        title?: string,
        subtitle?: string,
        img?: string,
        liens?: {
            icon?: string,
            text?: string
        }[],
        copyright?: string,
        text?: string,
    }
}

function Footer(props: FooterProps) {
    const { title, subtitle, img, liens, copyright, text } = props.footer;
    return (
        <footer className="text-center lg:text-left bg-gray-100 dark:bg-black text-gray-600">
            <div className="mx-[10%] py-10 text-center md:text-left">
                <div className="grid grid-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                    <div className="">
                        {title && <h6
                            className="
                            uppercase
                            font-semibold
                            mb-4
                            flex
                            items-center
                            justify-center
                            md:justify-start
                            "
                        >
                            {title}
                        </h6>
                        }
                        {subtitle && <p className='mb-6'>
                            {subtitle}
                        </p>
                        }
                        {img && <p className='flex items-center justify-center'>
                            <img className='w-[50%]' src={LaGrandeMoisson} alt="LaGrandeMoisson" />
                        </p>}
                    </div>
                    <div className=""></div>
                    <div className="">

                    </div>
                    {liens &&
                        <div className="">
                            <h6 className="uppercase font-semibold mb-4 flex justify-center md:justify-start">
                                Réseaux
                            </h6>
                            {/* <p className="flex items-center justify-center md:justify-start mb-4">
                                <a href="#!" className="mr-6 text-gray-600">
                                    <svg
                                        aria-hidden="true"
                                        focusable="false"
                                        data-prefix="fab"
                                        data-icon="facebook-f"
                                        className="w-2.5"
                                        role="img"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 320 512"
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                                        ></path>
                                    </svg>
                                </a>
                                Facebook
                            </p>
                            <p className="flex items-center justify-center md:justify-start mb-4">
                                <a href="#!" className="mr-6 text-gray-600">
                                    <svg
                                        aria-hidden="true"
                                        focusable="false"
                                        data-prefix="fab"
                                        data-icon="google"
                                        className="w-3.5"
                                        role="img"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 488 512"
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
                                        ></path>
                                    </svg>
                                </a>{' '}
                                E-mail
                            </p>
                            <p className="flex items-center justify-center md:justify-start mb-4">
                                <a href="#!" className="mr-6 text-gray-600">
                                    <svg
                                        aria-hidden="true"
                                        focusable="false"
                                        data-prefix="fab"
                                        data-icon="google"
                                        className="w-3.5"
                                        role="img"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 488 512"
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M448 0H64C28.7 0 0 28.7 0 64v288c0 35.3 28.7 64 64 64h96v84c0 7.1 5.8 12 12 12 2.4 0 4.9-.7 7.1-2.4L304 416h144c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64zm16 352c0 8.8-7.2 16-16 16H288l-12.8 9.6L208 428v-60H64c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16h384c8.8 0 16 7.2 16 16v288z"
                                        ></path>
                                    </svg>
                                </a>{' '}
                                Whatsapp
                            </p> */}
                            <p className="flex items-center justify-center md:justify-start">
                                {/* <a href="#!" className="mr-6 text-red-600">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 3333 2376"
                                        shape-rendering="geometricPrecision"
                                        text-rendering="geometricPrecision"
                                        image-rendering="optimizeQuality"
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M3193 548c-4-24-34-208-113-287l-1-1c-97-101-207-112-269-118l-20-2c-448-33-1121-33-1123-33h-1c-2 0-674 0-1123 33-3 0-12 1-22 2-62 6-172 17-268 118l-1 1c-80 81-110 271-112 288 0 1-32 258-32 516v244c0 258 32 515 32 516v1c3 20 33 208 113 288l1 1c88 92 204 106 279 115 17 2 32 4 46 7 259 24 1087 32 1090 32 17 0 678-2 1124-33l20-2c62-6 172-17 269-118l1-1c80-81 110-271 113-288 0-1 32-258 32-516v-244c-1-235-27-472-32-511 0-2-1-4-1-7zm-36-362c99 101 136 306 142 343 1 2 1 5 1 7 0 1 32 263 33 529v244c0 264-32 528-33 529v1c0 1-34 238-142 349l-1 1c-125 130-260 144-337 151-4 0-8 1-19 2h-3c-451 32-1131 33-1133 33s-836-7-1099-32c-2 0-5 0-7-1-10-2-24-4-41-6-89-11-229-28-344-148-102-104-138-321-142-347 0-1 0-3-1-4 0-1-32-265-32-529v-244c0-264 32-528 33-529v-1c0-1 33-238 141-349l1-1C298 54 433 41 510 33c5 0 9-1 20-2h3C987-2 1663-2 1665-2h1c2 0 677 0 1131 33h4c12 1 15 2 19 2 76 7 212 21 337 151zM1279 1606V699c0-30 24-54 54-54 11 0 21 3 29 9l867 453c26 14 36 46 23 72-5 10-13 18-23 23l-871 452c-26 14-59 3-72-23-4-8-6-16-6-25zm108-818v730l702-364-702-366z"

                                        />
                                    </svg>
                                </a>{' '} */}
                                Youtube
                            </p>
                        </div>}
                </div>
            </div>
            <div className="text-center p-6 bg-gray-200 dark:bg-black">
                {copyright && <span>{copyright}</span>}
                {text && <a className="text-gray-600 font-semibold dark:text-blue-500" href="#">
                    {text}
                </a>}
            </div>
        </footer>
    )
}

export default Footer
