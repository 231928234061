import React, { ComponentElement } from 'react';

export const AppText = {
  fr: {
    header: {
      LogoText: "Dico-Biblique",
      Menus: [
        ['Accueil', '/', '', ''],
        ['Contact', '/contact', '', ''],
        ['FAQ', '/faq', '', '']
      ],
      BtnAction: {
        text: "Démo",
        link: "demo"
      }
    },
    home: {
      Title: "Des définitions qui vont encore plus loin.",
      SubTitle: "Des milliers et des milliers d'expressions, de mots et de personnages bibliques décortiqués.",
      BtnText: "Essayez de vous-même",
      BtnLink: "demo",
      BtnIcon: "#",
    },
    parallax: {
      text: "Un dictionnaire certes, mais pas seulement.",
      img: "parallax"
    },
    wish: {
      text: `
        Ce travail est avant tout une œuvre que le Seigneur Yehowshuw‘a ha-Mashiyah a initiée pour l'édification des saints partout dans le monde. 
        Il nous a conduit depuis plusieurs années à rédiger un dictionnaire autour des mots et expressions 
        en lien avec la bible. Cela a nécessité plusieurs démarches en termes de recherche de mots, 
        de réformation des mots et de restitution de leur véritable sens.
        Dans cette version web du dictionnaire vous retrouverez :
      `,
      list: [
        "des définitions étoffées,",
        "une interface minimaliste, pratique et personnalisable,",
        "des mises à jour régulières,",
        "une bible intégrée,",
        "un cahier de notes numériques,",
        "et bien plus encore.",
      ]
    },
    subscribe: {
      title: "Soyez les premiers informés...",
      subtitle: "Laissez-nous votre adresse électronique et nous vous préviendrons dès que vous pourrez y accéder",
      litletext: "En cliquant sur s'inscrire vous acceptez d'être ajouté à la liste de diffusion des informations liées au dictionnaire biblique.",
      placeholder: "Votre E-mail",
      btnText: "S'inscrire",
      footerText: "En guise d'encouragement, nous vous enverrons, dans votre boite mail, quelques définitions tirées de la version finale et cela en exclusivité.",
    },
    faq: {
      icon: 'Message',
      title: "Les questions qu'on nous pose le plus souvent",
      subtitle: "Nous répondons à toutes les questions qu'on nous adresse. Et si vous ne trouvez toujours pas la réponse à votre question",
      link: {
        text: "écrivez-nous !",
        url: "/contact",
      },
      item: [
        {
          question: "Pourquoi un Dictionnaire Biblique ?",
          answer: `
          Cet ouvrage a été rédigé dans le but d’apporter des éclairages sur  des sujets mal abordés ou mal définis en milieu évangélique. `
        },
        {
          question: "Dans quelles circonstances a été réalisé ce travail?",
          answer: "Avec l’appui du Seigneur  Yehowshuw‘a ha-Mashiyah notre ’Elohiym, nous nous sommes organisés en équipes.",
        },
        {
          question: "Sous quel format retrouvera-t-on l'ouvrage ? ",
          answer: "L'ouvrage existera sous trois formats: format papier, format numérique PDF, et en application web et mobile. Vous pouvez essayer la démo grâce au bouton 'Démo'",

        },
        {
          question: "L'ouvrage est-il payant ?",
          answer: "Non, ce dictionnaire est totalement gratuit. En effet, nous avons reçu gratuitement du Seigneur ces éclairages, et nous les donnons à notre tour gratuitement.",
        },
        {
          question: "L'ouvrage est-il déjà disponible ? ",
          answer: "Pour le moment seule la démo est disponible. La rédaction, la mise en forme du document, et le développement de la plateforme numérique sont encore en cours. Nous vous invitons à vous inscrire à la newsletter afin d'être tenu informé des évolutions du travail jusqu'à son ouverture finale. ",
        },
      ],
      tag: {
        title: "Vous avez toujours une question ?",
        subtitle: "Posez-là à notre équipe en cliquant là-bas...",
        btnText: "Poser ma question",
        link: "/"
      },
      testimonial: [
        {
          text: "Pour que les vrais amoureux du Seigneur Yehowshuw‘a ha-Mashiyah plongent plus loin dans sa parole.",
          name: "Felix",
          tag: "Gabon"
        }
      ]
    },
    contact: {
      icon: 'contact',
      title: "Donnez-nous de vos nouvelles !",
      subtitle: "Une question, une suggestion, une édification, laissez nous un mot ",
      name: {
        label: "Votre nom",
        placeholder: "Myriam ESTHER",
      },
      email: {
        label: "Votre E-mail",
        placeholder: "myriam.esther@domain.com",
      },
      topic: {
        label: "A quel sujet se rapporte votre message",
        item: [
          "",
          "Une question sur le Dico-biblique",
          "Une question sur La Grande Moisson",
          "Un soutien au projet",
          "Autre",
        ],
      },
      message: {
        label: "Votre E-mail",
        placeholder: "myriam.esther@domain.com",
      },
      btn: {
        text: "Poser ma question",
        link: "/#"
      },
      msg: {
        success: "Très bien ! Vous êtes maintenant ajouté à la liste d'information.",
        warning: "Attention à la saisie des champs, veuillez vérifier que tout est bon.",
        error: "Une erreur est arrivée, veuillez réessayer plus tard."
      },
    },
    footer: {
      title: "Dico-Biblique",
      subtitle: "Ce dictionnaire biblique a été réalisé par La Grande Moisson, une association chrétienne à but non lucratif oeuvrant dans le social, le médical et la prédication de la Parole du véritable ’Elohiym.",
      img: 'LaGrandeMoisson',
      liens: [
        {
          icon: "Facebook",
          text: "Facebook",
          link: ""
        },
        {
          icon: "Gmail",
          text: "E-mail",
          link: "contact"
        },

        {
          icon: "Youtube",
          text: "Youtube",
          link: "https://youtube.com/@DICOBIBLIQUE"
        },
      ],
      copyright: "© 2023 Copyright:",
      text: "La Grande Moisson",
    }
  }
}

export const FeatureZoneDatas = [
  {
    icon: "DbText",
    title: "Des définitions riches",
    description:
      `Découvrez des mots riches en langue, en histoire, en information, en sens, en source, en référence... Retrouvez le sens originel des mots, leur histoire, et comment en fonction du contexte ceux-ci ont subi des modifications majeures.`,
  },
  {
    icon: "DbBook",
    title: "Des mots de la bible et hors de la bible",
    description:
      `Le dictionnaire biblique traitera de quelques mots que l’on trouve dans la bible et de ceux appartenant au jargon chrétien tels que catholicisme, protestantisme, chantronat etc.`
  },
  {
    icon: "DbConsole",
    title: "Une interface épurée",
    description:
      `La plateforme du dictionnaire biblique se veut simple, minimaliste, et intuitive. C'est pourquoi nous avons choisi ce mode de présentation que vous pourrez vous approprier directement en personnalisant l'interface. 
`  },
];


export const FeatureDetailsDatas = [
  {
    num: '01',
    tag: 'Définitions',
    title: 'Vous saurez tout ou presque des mots de la bible',
    subtitle: "Des définitions remplies de détails, tels que l'origine, l'étymologie, l'écriture hébraïque et bien plus encore",
    details: [

      {
        emoji: '🔥',
        text: "Au-delà du mot en lui-même, nous retrouvons le sens profond de ce dernier, et cela grâce aux recherches minutieuses que le Seigneur nous a permis de faire."
      },
    ],
    img: 'MotRiche'
  },
  {
    num: '02',
    tag: '4 Différentes parties',
    title: 'De nombreuses expressions en lien avec la bible...',
    subtitle: "Plusieurs expressions issues de la bible, mais aussi celles s'y rapportant",
    details: [
      {
        emoji: '🌟',
        text: "Dans nos échanges, nos partages, nous ne cessons d'utiliser des mots de la bible et plusieurs autres mots et expressions courants. Mais quelle est réellement la valeur des mots que l'on emploie dans le quotidien ? Cet ouvrage tente d'y répondre."
      },
    ],
    img: 'MotDansLaBibleEtHors'
  },
  {
    num: '03',
    tag: 'Interface personnalisable',
    title: 'Intuitive, minimaliste et simplement fonctionnelle',
    subtitle: "La plateforme finale comporte d'une part des éléments de navigations basiques, mais également des fonctionnalités propres au dictionnaire",
    details: [
      {
        emoji: '👍',
        text: "Les références bibliques en rapport avec les mots sont directement accessibles grâce à l'intégration de la Bible de Yehowshuw‘a ha-Mashiyah."
      },
    ],
    img: 'InterfaceIntuitive'
  },
]