import React from 'react'
import {
  Header,
  HomeZone,
  FeatureZone,
  Parallax,
  WishZone,
  SubscribeZone,
  Footer
} from '../components'


import { FeatureZoneDatas, FeatureDetailsDatas, AppText } from '../model/AppDatas'

interface HomeProps {
  title?: string
}

function Home(props: HomeProps) {
  const { title, subtitle, img, liens, copyright, text } = AppText.fr.footer;

  const { footer } = AppText.fr;

  return (
    <div className='screen'>
      <Header />
      <HomeZone />
      <FeatureZone 
        feature={FeatureZoneDatas}
        details={FeatureDetailsDatas}
      />
      <Parallax />
      <WishZone />
      <SubscribeZone />
      <Footer {...{ footer }} />
    </div>
  )
}

export default Home
