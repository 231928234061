import React, { useContext } from "react";
import "./Header.sass";
import { Outlet, Link } from "react-router-dom";

import { ThemeContext } from "../../App";
import { AppText } from "../../model/AppDatas";

import { Dark, Light } from "../../assets/";

interface HeaderProps {
  test?: string;
}

function Header(props: HeaderProps) {
  const [dark, setDark] = useContext(ThemeContext);
  const menus = AppText.fr.header.Menus;
  return (
    <>
      <div className="nav">
        <Link to="/">
          <a href="/#" className="nav__logo">
            {AppText.fr.header.LogoText}
          </a>
        </Link>

        <nav className="nav__bloc">
          {menus.map(([title, url, classes, act]) => (
            <Link to={url}>
              <a href={url} className={`nav__item ${classes}`}>
                {title}
              </a>
            </Link>
          ))}
          <Link
            to={`/${AppText.fr.header.BtnAction.link}`}
            className="nav__item shad btn btn-main "
          >
            {AppText.fr.header.BtnAction.text}
          </Link>
          <div>
            {dark === true ? (
              <Light
                onClick={() => {
                  setDark(!dark);
                }}
                className="icon-light"
              />
            ) : (
              <Dark
                onClick={() => {
                  setDark(!dark);
                  console.log(dark);
                }}
                className="icon-dark"
              />
            )}
          </div>
        </nav>
      </div>
      <nav className="nav__bloc-mobile">
        {menus.map(([title, url, classes, act]) => (
          <Link to={url}>
            <a href={url} className={`nav__item ${classes}`}>
              {title}
            </a>
          </Link>
        ))}
        <Link
          to={`/${AppText.fr.header.BtnAction.link}`}
          className="nav__item shad btn btn-main "
        >
          {AppText.fr.header.BtnAction.text}
        </Link>
        <div>
          {dark === true ? (
            <Light
              onClick={() => {
                setDark(!dark);
              }}
              className="icon-light"
            />
          ) : (
            <Dark
              onClick={() => {
                setDark(!dark);
                console.log(dark);
              }}
              className="icon-dark"
            />
          )}
        </div>
      </nav>
    </>
  );
}

export default Header;
