import React, { useContext } from "react";
import { FeatureDetailPropsTypes } from "../../../types/AppTypes";
import {
  DbIllustrate,
  DbIllustrateDark,
  MotRiche,
  MotRicheDark,
  InterfaceIntuitive,
  InterfaceIntuitiveDark,
  MotDansLaBibleEtHors,
  MotDansLaBibleEtHorsDark,
} from "../../../assets";
import { ThemeContext } from "../../../App";

function FeatureDetail({
  tag,
  title,
  subtitle,
  details,
  img,
}: FeatureDetailPropsTypes) {
  const [dark, setDark] = useContext(ThemeContext);

  const theimg = (img: string): any => {
    if (img === "MotRiche") {
      if (dark) {
        return MotRicheDark;
      } else {
        return MotRiche;
      }
    }
    if (img === "InterfaceIntuitive") {
      if (dark) {
        return InterfaceIntuitiveDark;
      } else {
        return InterfaceIntuitive;
      }
    }
    if (img === "MotDansLaBibleEtHors") {
      if (dark) {
        return MotDansLaBibleEtHorsDark;
      } else {
        return MotDansLaBibleEtHors;
      }
    }
  };

  return (
    <div className="feature__detail">
      {tag && <div className="feature__detail-tag">{tag} </div>} 
      <div className="feature__detail-zone">
        <div className="feature__detail-left">
          {title && <div className="feature__detail-title">{title}</div>}
          {subtitle && (
            <div className="feature__detail-subtitle">{subtitle}</div>
          )}
          {details && (
            <div className="feature__details-izone">
              {details?.map(({ emoji, text }, index) => (
                <div className="feature__details-item" key={index}>
                  {emoji && <i className="feature__details-emoji">{emoji}</i>}
                  {text && <div className="feature__details-text">{text}</div>}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="feature__detail-right">
          {img && <img src={theimg(img as string)} alt="illustrate" />}
        </div>
      </div>
      <hr className="feature__detail-divider" />
    </div>
  );
}

export default FeatureDetail;
