import React from "react";
import { Header, DemoZone, Footer } from "../components";

import { Outlet, Link } from "react-router-dom";
import { AppText } from "../model/AppDatas";

interface HomeProps {
  title?: string;
}

function Faq(props: HomeProps) {
  const { icon, title, subtitle, link } = AppText.fr.faq;

  const { footer } = AppText.fr;

  return (
    <div className="overflow-y-hidden max-h-screen">
      <div className="">
        <Header />
        <div className="visible sm:hidden text-center text-red-400 italic text-[1em]  ">
          😊 Utilisez le mode paysage pour une meilleur expérience.
        </div>
        <div className="text-center text-gray-500 italic text-[0.7em] py-1 ">
          Ceci n'est pas la plateforme finale mais un aperçu du travail qui se
          prépare. Pour toute question 👉
          <Link to="/faq">
            <a href="/faq" className="text-blue-800 cursor-pointer">
              FAQ
            </a>
          </Link>
        </div>
      </div>
      <DemoZone />
      {/* <Footer {...{ footer }} /> */}
    </div>
  );
}

export default Faq;
