import React, { useContext } from 'react'
import './Parallax.sass'

import { ThemeContext } from '../../App'
import { AppText } from '../../model/AppDatas'
interface HeaderProps {
  test?: string
}

function Parallax(props: HeaderProps) {
  const [dark, setDark] = useContext(ThemeContext)
  const { text, img } = AppText.fr.parallax;
  return (
    <div className={dark === true ? 'parallax-dark' : 'parallax-light'}>
      {text && <p className={dark === true ? 'parallax__text-dark' : 'parallax__text-light'}>
        {text}
      </p>}
    </div>
  )
}

export default Parallax
