import React, { useContext } from "react";
import "./HomeZone.sass";
import { Outlet, Link } from "react-router-dom";

import { db_home, db_home_dark } from "../../assets";
import { AppText } from "../../model/AppDatas";

import { ThemeContext } from "../../App";

interface HomeZoneProps {
  test?: string;
}

function HomeZone(props: HomeZoneProps) {
  const [dark, setDark] = useContext(ThemeContext);

  return (
    <div>
      <div className="cloud cloud-l">
        <svg
          className=""
          viewBox="0 0 1155 678"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="url(#45de2b6b-92d5-4d68-a6a0-9b9b2abad533)"
            fill-opacity=".3"
            d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
          />
          <defs>
            <linearGradient
              id="45de2b6b-92d5-4d68-a6a0-9b9b2abad533"
              x1="1155.49"
              x2="-78.208"
              y1=".177"
              y2="474.645"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#1eaafd"></stop>
              <stop offset="1" stop-color="#FF80B5"></stop>
            </linearGradient>
          </defs>
        </svg>
      </div>

      <main>
        <div className="home__view">
          {AppText.fr.home.Title && (
            <h1 className="title-xl text-gradient">{AppText.fr.home.Title}</h1>
          )}
          {AppText.fr.home.SubTitle && (
            <p className="subtitle">{AppText.fr.home.SubTitle}</p>
          )}
          {AppText.fr.home.BtnLink && (
            <div className="btn-bloc">
              <Link to={`/${AppText.fr.home.BtnLink}`}>
                <a href="/#" className="btn btn-main btn-lg">
                  {AppText.fr.home.BtnText}
                  <span className="btn-icon" aria-hidden="true">
                    &rarr;
                  </span>
                </a>
              </Link>
            </div>
          )}
        </div>
      </main>

      <div className="cloud cloud-r">
        <svg
          className=""
          viewBox="0 0 1155 678"
          fill="yellow"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="cyan"
            fill-opacity=".3"
            d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
          />
        </svg>
      </div>

      <div className="cover-img ">
        <img
          src={dark ? db_home_dark : db_home}
          className="feature__details-img "
          alt="db"
        />
      </div>
    </div>
  );
}

export default HomeZone;
