import React from 'react';
import './WishZone.sass';
import { AppText } from '../../model/AppDatas'

interface WishZoneProps {
    test?: string;
}

function WishZone(props: WishZoneProps) {
    const { text, list } = AppText.fr.wish;

    return (
        <div className='wish'>
            {text &&
                <p className='wish__text'>
                    {text}
                </p>
            }
            {list &&
                <ul className='wish__ul'>
                    {
                        list.map((el, index) =>
                            <li className='wish__li' key={index}>{el}</li>
                        )
                    }
                </ul>

            }
        </div>
    );
}

export default WishZone;